a {
    @apply text-inherit hover:text-inherit ring-primary outline-primary !important;
}

button, select, input, textarea {
  @apply border-gray-300 hover:border-primary-light cursor-pointer;
  @apply ring-0 outline-0 focus:ring-0 focus:outline-0 focus:border-primary-light !important;
}

select:disabled, input:disabled, textarea:disabled {
  @apply bg-gray-100 hover:border-inherit cursor-not-allowed;
}

.form-item-enabled {
    @apply cursor-pointer bg-white;
}

.form-item-disabled {
    @apply cursor-not-allowed bg-gray-200;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  @apply border-[1.1em] border-t-primary border-r-primary border-l-primary text-[10px] my-[60px] mx-auto relative;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
